.header {
    background-color: #333333;
    padding: 20px 0;
  }
  
  .headerContent {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 1200px;
    margin: 0 auto;
    position: relative;
  }
  
  .headerImage {
    max-width: 100%;
    height: auto;
  }
  
  .themeToggle {
    position: absolute;
    right: 20px;
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
    color: #999999;
  }
  
  @media (max-width: 768px) {
    .headerImage {
      max-width: 80%;
    }
    
    .themeToggle {
      right: 10px;
      font-size: 20px;
    }
  }