.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modalContent {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  max-width: 400px;
  width: 100%;
  text-align: center;
}

.buttonGroup {
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
}

.exportButton, .cancelButton {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.exportButton {
  background-color: #4caf50;
  color: white;
}

.exportButton:hover {
  background-color: #45a049;
}

.cancelButton {
  background-color: #f44336;
  color: white;
}

.cancelButton:hover {
  background-color: #d32f2f;
}

.exportButton {
  padding: 10px 20px;
  margin: 0 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
  text-decoration: none;
  display: inline-block;
  text-align: center;
}

.exportButton:first-of-type {
  background-color: #4caf50;
  color: white;
}

.exportButton:nth-of-type(2) {
  background-color: #f44336;
  color: white;
}

.exportButton:hover {
  opacity: 0.8;
}
