/* TokenReport.module.css */

/* Define CSS variables for colors */
:root {
  --light-section-title-bg: #CCCCCC;
  --light-section-bg: #FFFFFF;
  --dark-section-title-bg: #999999;
  --dark-section-bg: #CCCCCC;
  --text-color-light: #333333;
  --text-color-dark: #000000;
  --accent-color: #4a90e2;
}

.tokenReport {
  font-family: 'Roboto', sans-serif;
  color: var(--text-color-light);
  max-width: 800px;
  margin: 0 auto;
}

.tokenReport h2 {
  color: var(--accent-color);
  border-bottom: 2px solid var(--accent-color);
  padding-bottom: 10px;
}

.section {
  margin-bottom: 20px;
  background-color: var(--light-section-bg);
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.section h3 {
  background-color: var(--light-section-title-bg);
  margin: 0;
  padding: 15px;
  font-size: 1.2em;
}

.section p {
  padding: 10px 15px;
  margin: 0;
  border-bottom: 1px solid #eee;
}

.section p:last-child {
  border-bottom: none;
}

.toggleButton {
  background-color: var(--accent-color);
  color: white;
  border: none;
  padding: 10px 15px;
  margin: 15px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1em;
  transition: background-color 0.3s ease;
}

.toggleButton:hover {
  background-color: #357ae8;
}

.insiderList {
  margin: 15px;
  background-color: #f5f5f5;
  border-radius: 4px;
  padding: 10px;
}

.insiderList h4 {
  margin-top: 0;
  color: var(--accent-color);
}

.insiderList ul {
  list-style-type: none;
  padding: 0;
}

.insiderList li {
  display: flex;
  justify-content: space-between;
  padding: 5px 0;
  border-bottom: 1px solid #eee;
}

.insiderList li:last-child {
  border-bottom: none;
}

.exportButton {
  background-color: #4caf50;
  color: white;
  border: none;
  padding: 10px 20px;
  margin-top: 20px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1em;
  transition: background-color 0.3s ease;
}

.exportButton:hover {
  background-color: #45a049;
}

/* Dark theme styles */
:global(.dark) .tokenReport {
  color: var(--text-color-dark);
}

:global(.dark) .section {
  background-color: var(--dark-section-bg);
}

:global(.dark) .section h3 {
  background-color: var(--dark-section-title-bg);
}

:global(.dark) .insiderList {
  background-color: #444444;
}

:global(.dark) .insiderList li {
  border-bottom-color: #555555;
}

/* Responsive design */
@media (max-width: 768px) {
  .tokenReport {
    padding: 0 15px;
  }

  .section h3 {
    font-size: 1.1em;
  }

  .toggleButton,
  .exportButton {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }
}