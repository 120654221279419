body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Light theme variables */
:root {
  --bg-color: #f0f0f0;
  --text-color: #333;
  --card-bg: #ffffff;
  --primary-color: #4a90e2;
}

/* Dark theme variables */
[data-theme='dark'] {
  --bg-color: #333;
  --text-color: #f0f0f0;
  --card-bg: #444;
  --primary-color: #61dafb;
}

body {
  background-color: var(--bg-color);
  color: var(--text-color);
}