@font-face {
  font-family: 'Roboto';
  src: url('./fonts/Roboto-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

/* Define CSS variables for colors */
:root {
  --light-section-title-bg: #CCCCCC;
  --light-section-bg: #FFFFFF;
  --dark-section-title-bg: #999999;
  --dark-section-bg: #CCCCCC;
  --text-color-light: #333333;
  --text-color-dark: #000000;
}

.report {
  font-family: 'Roboto', sans-serif;
  color: var(--text-color-light);
}

.section {
  margin-bottom: 20px;
}

.sectionHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--light-section-title-bg);
  padding: 10px;
  cursor: pointer;
  border-radius: 4px;
}

.sectionContent {
  padding: 10px;
  background-color: var(--light-section-bg);
  border-radius: 0 0 4px 4px;
}

.collapseIcon {
  font-size: 18px;
  transition: transform 0.3s ease;
}

.sectionHeader[aria-expanded="true"] .collapseIcon {
  transform: rotate(180deg);
}

.tokenColumns, .nftColumns {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  margin-bottom: 20px;
}

.tokenItem, .nftItem {
  background-color: #c5c5c5;
  border-radius: 4px;
  padding: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.tokenName, .nftName {
  font-weight: bold;
  margin-bottom: 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}

.tokenBalance, .nftId {
  color: #4a90e2;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}

.tokenLink {
  color: #4a90e2;
  text-decoration: none;
  margin-top: 5px;
}

.nftImage {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 4px;
  margin-bottom: 10px;
}

.paginationControls {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.paginationButton {
  background-color: #4a90e2;
  color: white;
  border: none;
  padding: 5px 10px;
  margin: 0 5px;
  border-radius: 4px;
  cursor: pointer;
}

.paginationButton:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.filterSortControls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.filterSortControls label {
  display: flex;
  align-items: center;
}

.filterSortControls input[type="checkbox"] {
  margin-right: 5px;
}

.sortButton {
  background-color: #4a90e2;
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 4px;
  cursor: pointer;
}

.insiderList {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
}

.insiderItem {
  background-color: #f9f9f9;
  border-radius: 4px;
  padding: 15px;
}

.insiderAddress {
  font-weight: bold;
  margin-bottom: 5px;
}

.insiderPercentage {
  color: #4a90e2;
}

.metadataBox {
  background-color: #f9f9f9;
  border-radius: 4px;
  padding: 15px;
  max-height: 300px;
  overflow-y: auto;
}

.metadataBox pre {
  margin: 0;
  white-space: pre-wrap;
  word-wrap: break-word;
}

.copyButton {
  background-color: #4a90e2;
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 4px;
  cursor: pointer;
  margin-left: 10px;
}

.exportButton {
  display: block;
  width: 100%;
  padding: 10px;
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  margin-top: 20px;
}

@media (max-width: 768px) {
  .tokenColumns, .nftColumns, .insiderList {
    grid-template-columns: 1fr;
  }

  .filterSortControls {
    flex-direction: column;
    align-items: flex-start;
  }

  .filterSortControls label, .sortButton {
    margin-bottom: 10px;
  }
}

/* Dark theme styles */
:global(.dark) .report {
  color: var(--text-color-dark);
}

:global(.dark) .sectionHeader {
  background-color: var(--dark-section-title-bg);
}

:global(.dark) .sectionContent {
  background-color: var(--dark-section-bg);
}

:global(.dark) .tokenItem, 
:global(.dark) .nftItem, 
:global(.dark) .insiderItem, 
:global(.dark) .metadataBox {
  background-color: var(--dark-section-bg);
}

:global(.dark) .tokenBalance, 
:global(.dark) .nftId, 
:global(.dark) .tokenLink, 
:global(.dark) .insiderPercentage {
  color: #61dafb;
}

:global(.dark) .paginationButton,
:global(.dark) .sortButton,
:global(.dark) .copyButton {
  background-color: #61dafb;
  color: #000000;
}

:global(.dark) .paginationButton:disabled {
  background-color: #555555;
  color: #aaaaaa;
}

:global(.dark) .exportButton {
  background-color: #45a049;
}

.transactionHistory {
  width: 100%;
  overflow-x: auto;
}

.tableWrapper {
  width: 100%;
  overflow-x: auto;
}

.transactionTable {
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
}

.transactionTable th,
.transactionTable td {
  padding: 8px;
  text-align: left;
  border-bottom: 1px solid #ddd;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.transactionTable.expanded th,
.transactionTable.expanded td {
  white-space: normal;
  word-wrap: break-word;
}

.transactionTable th {
  background-color: #f2f2f2;
  font-weight: bold;
}

.expandButton {
  margin-top: 10px;
  padding: 5px 10px;
  background-color: #4a90e2;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.expandButton:hover {
  background-color: #357ae8;
}

.addressInfoContent {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px; /* Add space between address details and icons */
}

.addressDetails {
  flex: 1;
  min-width: 0; /* Allows the content to shrink if needed */
}

.addressIcons {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px; /* Space between icons */
}

.iconWrapper {
  width: 64px;
  height: 64px;
}

.iconWrapper > div {
  width: 100% !important;
  height: 100% !important;
}

.addressIcons > div {
  margin-bottom: 10px;
}

.blockies {
  width: 64px;
  height: 64px;
  border-radius: 50%;
}

@media (max-width: 768px) {
  .addressInfoContent {
    flex-direction: column;
    align-items: flex-start;
  }

  .addressIcons {
    flex-direction: row;
    margin-top: 10px;
  }
}

.webacyExposureRisk {
  margin-top: 10px;
}

.webacyExposureRisk h4 {
  color: var(--accent-color);
  margin-bottom: 10px;
}

.webacyExposureRisk p {
  margin: 5px 0;
}