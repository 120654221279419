@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap');

.app {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  font-family: 'Roboto', sans-serif;
}

.light {
  background-color: #f5f5f5;
  color: #333333;
}

.dark {
  background-color: #121212;
  color: #000000;
}

.container {
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  padding: 20px;
  box-sizing: border-box;
  flex-grow: 1;
}

.header, .footer {
  background-color: #000000;
  color: #ffffff;
  padding: 20px;
  text-align: center;
}

.card {
  background-color: #ffffff;
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.dark .card {
  background-color: #333333;
}

.searchBarWrapper {
  margin-bottom: 20px;
}

.searchForm {
  display: flex;
  gap: 10px;
}

.searchInput {
  flex-grow: 1;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.searchButton {
  padding: 10px 20px;
  font-size: 16px;
  background-color: #4a90e2;
  color: #ffffff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.searchButton:hover {
  background-color: #357ae8;
}

.searchButton:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.errorAlert {
  background-color: #ff4136;
  color: #ffffff;
  padding: 10px;
  border-radius: 4px;
  margin-bottom: 20px;
  text-align: center;
}

.spinnerContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
}

.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  width: 36px;
  height: 36px;
  border-radius: 50%;
  border-left-color: #09f;
  animation: spin 1s ease infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.exportButton {
  display: block;
  width: 100%;
  padding: 10px;
  background-color: #4caf50;
  color: #ffffff;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.exportButton:hover {
  background-color: #45a049;
}

@media (max-width: 768px) {
  .container {
    padding: 10px;
  }

  .searchForm {
    flex-direction: column;
  }

  .searchInput, .searchButton {
    width: 100%;
  }
}