.searchBarContainer {
    width: 100%;
    margin-bottom: 20px;
  }
  
  .searchForm {
    display: flex;
    width: 100%;
  }
  
  .searchInput {
    flex-grow: 1;
    padding: 10px 15px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-right: none;
    border-radius: 4px 0 0 4px;
  }
  
  .searchButton {
    padding: 10px 20px;
    font-size: 16px;
    background-color: #0000FF;
    color: white;
    border: none;
    border-radius: 0 4px 4px 0;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .searchButton:hover {
    background-color: #0000CC;
  }
  
  .searchButton:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
  }